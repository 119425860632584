import { getErrorDetail } from "components/utility/utils";
import { fetchOrchidAPI } from "utils/api";

/**
 * Pay with existing payment method
 */
export default async function payWithExistingPaymentMethod(
  appointmentId: string | number,
  paymentMethodId: string,
) {
  const res = await fetchOrchidAPI(
    `/api/scheduler/v1/payment/pay_with_existing_payment_method/${appointmentId}`,
    {
      method: "POST",
      body: JSON.stringify({
        payment_method_id: paymentMethodId,
      }),
    },
  );
  if (!res.ok) {
    throw new Error(`payment failed: ${getErrorDetail(await res.text())}`);
  }

  return res.json();
}
