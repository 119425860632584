import { IS_DEV_ENV } from "constants/isDevEnv";
import { isNumber } from "lodash";

// The ID of the extension we want to talk to.
const EXTENSION_IDS = IS_DEV_ENV
  ? [
      "ibfmlijilgkldcbeinkpbahbklmiiija", // Ada
      "oicajlhbdgjmogaogibpjpjohfmaedio", // Adam
    ]
  : [
      "llecjojlphipbakjmafcpapcccilelkj", // Production
    ];

const queryExtensionForLastActivity = (extensionId: string): Promise<number> =>
  new Promise((resolve, reject) => {
    try {
      chrome.runtime.sendMessage(
        extensionId,
        { type: "get-last-active" },
        (response) => {
          const lastActive = response?.lastActive;
          if (!isNumber(lastActive)) {
            reject("No activity found");
          }
          resolve(lastActive);
        },
      );
    } catch (error) {
      reject(error);
    }
  });

export const getUserLastActiveInExtension = async () => {
  if (!globalThis.hasOwnProperty("chrome") || !chrome?.runtime) {
    return -Infinity;
  }

  const { lastError } = chrome.runtime;
  if (lastError) {
    console.warn("chrome.runtime.lastError:", lastError);
  }

  const results = await Promise.allSettled(
    EXTENSION_IDS.map(queryExtensionForLastActivity),
  );

  const fulfilledResult = results.find(
    (result) => result.status === "fulfilled",
  );

  if (!fulfilledResult) {
    return -Infinity;
  }

  return (fulfilledResult as PromiseFulfilledResult<number>).value;
};
