import ContentHeader from "components/content-header/ContentHeader";
import Loading from "components/Loading";
import OrchidProAvatar from "components/orchid-pro-avatar";
import RoundedImageWithFallback from "components/rounded-image-with-fallback";
import { useAuthModals } from "contexts/auth-modals-context";
import { usePhonePortraitContext } from "contexts/phone-portrait-context";
import { useUser } from "contexts/UserContext";
import moment from "moment-timezone";
import React, { useEffect, useMemo, useState } from "react";
import {
  Alert,
  Button,
  Container,
  Form,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import applyCouponDiscount from "services/appointment-checkout/applyCouponDiscount";
import booksAvailability from "services/appointment-checkout/booksAvailability";
import checkoutOneTime from "services/appointment-checkout/checkoutOneTime";
import confirmWithStripe from "services/appointment-checkout/confirmWithStripe";
import payWithExistingPaymentMethod from "services/appointment-checkout/payWithExistingPaymentMethod";
import queryPaymentMethods from "services/queryPaymentMethods";
import queryStripePromise from "services/queryStripePromise";
import querySessionTypesByPro from "services/scheduler/querySessionTypesByProId";
import { getOrchidProProfileImageUrl } from "utils/formatter/orchidPro";

import styles from "./AppointmentBook.module.scss";

const AppointmentCheckout = () => {
  const { user } = useUser();
  const { openAuthModals } = useAuthModals();
  const { isPhonePortrait } = usePhonePortraitContext();
  const { defaultUserTimezone } = useUser();
  const history = useHistory();
  const location = useLocation();

  const params = useParams<{
    id_or_custom_url?: string;
  }>();

  const { firstname, lastname, start, length, type, inviteCode } =
    useMemo(() => {
      const query = new URLSearchParams(location.search);
      return {
        firstname: query.get("firstname"),
        lastname: query.get("lastname"),
        start: query.get("start"),
        length: parseInt(query.get("length") || "0"),
        type: query.get("type"),
        inviteCode: query.get("invite_code"),
      };
    }, [location.search]);

  const { pro, timeslot } =
    (location.state as { pro: any; timeslot: any } | null) || {};

  const orchidProUserId = pro?.cognito_sub ?? params?.id_or_custom_url ?? "";
  const orchidProFirstName =
    pro?.preferred_first_name ?? pro?.first_name ?? firstname;
  const orchidProLastName = pro?.last_name ?? lastname;
  const appointmentStart = timeslot?.timeslot_datetime_start ?? start;
  const appointmentLength = timeslot?.timeslot_length_minute ?? length;
  const appointmentTypeLabel = timeslot?.appointmentType.label ?? type;

  const [busy, setBusy] = useState<boolean>(false);
  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();
  const [selectedAppointmentType, setSelectedAppointmentType] =
    useState<SessionType>();
  const [paymentList, setPaymentList] = useState<PaymentList[]>([]);
  const [selectedCardId, setSelectedCardId] = useState<string>();
  const [endPrice, setEndPrice] = useState<number | string | null>(null);
  const [couponName, setCouponName] = useState<string | null>(null);

  useEffect(() => {
    if (user !== undefined && !user?.signup_form_finished) {
      openAuthModals();
      return;
    }

    if (!orchidProUserId) return;

    setIsPageLoading(true);

    querySessionTypesByPro(orchidProUserId)
      .then((res) => {
        const selected = res.find((t) => t.label === appointmentTypeLabel);
        setSelectedAppointmentType(selected);
      })
      .catch(setError);

    queryPaymentMethods()
      .then(setPaymentList)
      .catch(setError)
      .finally(() => setIsPageLoading(false));
  }, [orchidProUserId, appointmentTypeLabel, user, openAuthModals]);

  const applyCoupon = async () => {
    setError(undefined);

    if (!selectedAppointmentType) return;

    if (!couponName) {
      setEndPrice(selectedAppointmentType.price);
      return;
    }

    try {
      const response = await applyCouponDiscount(
        selectedAppointmentType.label,
        couponName,
        orchidProUserId,
      );
      const { end_price } = response;

      setEndPrice(end_price);
    } catch (error) {
      setError(error);
    }
  };

  const handleCheckout = async () => {
    setBusy(true);
    setError(undefined);

    if (!selectedAppointmentType) return;

    try {
      const { timeslot_id: appointmentId, price } = await booksAvailability({
        appointmentLength,
        appointmentStart,
        orchidProUserId,
        appointmentTypeLabel: selectedAppointmentType.label,
        couponName,
        inviteCode,
        appointmentHeldPlace: null,
      });

      if (!appointmentId) return;

      if (parseFloat(price) === 0) {
        history.push({
          pathname: `/professional-search/${orchidProUserId}/checkout-confirm/${appointmentId}`,
        });
        return;
      }
      if (paymentList.length === 0) {
        await checkoutWithoutPaymentMethod(appointmentId);
      }
      await payWithPreviousPaymentMethod(selectedCardId, appointmentId);
    } catch (error) {
      setError(error);
    } finally {
      setBusy(false);
    }
  };

  const forwardToCheckout = async () => {
    if (!selectedAppointmentType) return;

    try {
      const { timeslot_id: appointmentId, price } = await booksAvailability({
        appointmentLength,
        appointmentStart,
        orchidProUserId,
        appointmentTypeLabel: selectedAppointmentType.label,
        couponName,
        inviteCode,
        appointmentHeldPlace: null,
      });

      if (!appointmentId) return;

      if (parseFloat(price) === 0) {
        history.push({
          pathname: `/professional-search/${orchidProUserId}/checkout-confirm/${appointmentId}`,
        });
        return;
      }
      await checkoutWithoutPaymentMethod(appointmentId);
    } catch (error) {
      setError(error);
    }
  };

  const checkoutWithoutPaymentMethod = async (appointmentId) => {
    if (!appointmentId) return;
    try {
      const data = await checkoutOneTime(appointmentId);
      const stripe = await queryStripePromise();
      if (!stripe) return;

      const result = await stripe.redirectToCheckout({
        sessionId: data.session_id,
      });
      if (result.error) {
        console.error(result.error);
        throw new Error(`redirect to checkout failed: ${result.error.message}`);
      }
    } catch (err) {
      setError(err);
    }
  };

  const payWithPreviousPaymentMethod = async (
    selectedCardId,
    appointmentId,
  ) => {
    if (!selectedCardId || !appointmentId) return;

    try {
      const response = await payWithExistingPaymentMethod(
        appointmentId,
        selectedCardId,
      );
      if (response.payment_intent) {
        await confirmWithStripe(response.payment_intent.client_secret);
      } else {
        await confirmWithStripe(response.setup_intent.client_secret);
      }
      history.replace(
        `/professional-search/${orchidProUserId}/checkout-confirm/${appointmentId}`,
      );
    } catch (error) {
      setError(error);
    }
  };

  const onInputChange = (event) => {
    const inputField = event.currentTarget;
    const payment_method_id = inputField.value;
    setSelectedCardId(payment_method_id);
  };

  if (isPageLoading) {
    return <Loading />;
  }

  if (!selectedAppointmentType) {
    return null;
  }

  return (
    <>
      <ContentHeader title={"Confirmation"} />
      <Container
        className={
          !isPhonePortrait
            ? styles["appointment-checkout-container"]
            : styles["appointment-checkout-container--phone"]
        }
      >
        <div className={styles["appointment-checkout-container__body"]}>
          {error && <Alert variant="danger">{error.toString()}</Alert>}
          <h1 className="rg-32 deep-blue mb-4">Confirm your booking</h1>
          <Row className="mb-2">
            {pro ? (
              <OrchidProAvatar
                orchidPro={pro}
                style={{
                  width: "3rem",
                  height: "3rem",
                }}
              />
            ) : (
              <RoundedImageWithFallback
                src={getOrchidProProfileImageUrl(orchidProUserId)}
                width={45}
                height={45}
              />
            )}
            <p className="mb-0 rg-14 blue ml-4">
              {orchidProFirstName || ""} {orchidProLastName || ""}
            </p>
          </Row>
          <h4 className="rg-24 deep-blue mb-0">
            {selectedAppointmentType.label}
          </h4>
          <p className="rg-14 gray02 mb-1">
            {moment
              .tz(appointmentStart, defaultUserTimezone)
              .format("MM/DD/YYYY, hh:mm A")}
          </p>
          <p className="rg-18">
            {endPrice === null
              ? `$${(Number(selectedAppointmentType.price) ?? 0).toFixed(2)}`
              : `$${(Number(endPrice) ?? 0).toFixed(2)}`}
          </p>

          {selectedAppointmentType.price !== 0 && (
            <Form.Group>
              <Form.Label className="rg-12 gray01">Coupon</Form.Label>
              <InputGroup>
                <Form.Control
                  type="text"
                  name="coupon"
                  value={couponName || ""}
                  onChange={(event) => setCouponName(event.target.value)}
                />
                <InputGroup.Append>
                  <Button
                    variant="primary"
                    disabled={!couponName}
                    onClick={applyCoupon}
                  >
                    Apply
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </Form.Group>
          )}

          {endPrice !== 0 && selectedAppointmentType.price !== 0 && (
            <>
              {paymentList.length === 0 ? (
                <Button
                  variant="primary"
                  className="w-100 mb-3"
                  size="lg"
                  onClick={forwardToCheckout}
                >
                  Add a new card
                </Button>
              ) : (
                <div className="d-flex justify-content-between align-items-center">
                  <Form.Group>
                    <Form.Label className="rg-12 gray01">Card</Form.Label>
                    <Form.Control
                      as="select"
                      custom
                      required
                      disabled={
                        paymentList.length === 0 ||
                        endPrice === 0 ||
                        selectedAppointmentType.price === 0
                      }
                      name="payment_method"
                      onChange={onInputChange}
                    >
                      <option value="">Select...</option>
                      {paymentList.map((paymentMethod) => {
                        const { brand, last4, exp_month, exp_year } =
                          paymentMethod.card || {};
                        const { id } = paymentMethod;
                        return (
                          <option value={id} key={id}>
                            {`${brand} ***${last4} ${exp_month}/${exp_year}`}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Form.Group>

                  <Button variant="link" onClick={forwardToCheckout}>
                    Add a new card
                  </Button>
                </div>
              )}
            </>
          )}
          {!user?.signup_form_finished && (
            <Button size="lg" className="w-100" onClick={openAuthModals}>
              {"Complete registration"}
            </Button>
          )}
          {user?.signup_form_finished && (
            <Button
              size="lg"
              className="w-100 d-flex align-items-center justify-content-center"
              disabled={
                !(
                  endPrice === 0 ||
                  selectedAppointmentType.price === 0 ||
                  selectedCardId
                )
              }
              onClick={handleCheckout}
            >
              Confirm & finish{" "}
              {busy && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="ml-3"
                />
              )}
            </Button>
          )}

          <Button
            as={Link}
            to={`/professional-search/${orchidProUserId}`}
            size="lg"
            variant="link"
            className="w-100 mt-2"
          >
            Edit session details
          </Button>
        </div>
      </Container>
    </>
  );
};

export default AppointmentCheckout;
