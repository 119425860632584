import OrchidProPracticeGroupIcon from "components/orchid-pro-practice-group-icon";
import RoundedImageWithFallback from "components/rounded-image-with-fallback";
import { usePhonePortraitContext } from "contexts/phone-portrait-context";
import React, { HTMLProps } from "react";
import { useHistory } from "react-router-dom";
import { apiHostAndPath } from "utils/api";
import getClasses from "utils/getClasses";
import styles from "./styles.module.scss";

type Props = {
  orchidPro: Pick<
    OrchidPro,
    "cognito_sub" | "practice_group_name" | "heard_about_orchid_via"
  >;
  mainClassName?: string;
  groupClassName?: string;
  hideGroup?: boolean;
} & HTMLProps<HTMLDivElement>;

const OrchidProAvatar = ({
  className,
  mainClassName,
  groupClassName,
  orchidPro,
  hideGroup,
  ...props
}: Props) => {
  const { isPhonePortrait } = usePhonePortraitContext();
  const history = useHistory();
  const redirectToGroupPage = (e) => {
    e.stopPropagation();
    const newUrl = `/${orchidPro.practice_group_name}`;
    if (history) {
      history.push({
        pathname: newUrl,
        state: { orchidPro: orchidPro },
      });
    }
  };
  return (
    <div
      className={getClasses(
        styles["orchid-pro-avatar"],
        isPhonePortrait && styles["orchid-pro-avatar--phone-view"],
        className,
      )}
      {...props}
    >
      {orchidPro.cognito_sub && (
        <RoundedImageWithFallback
          src={apiHostAndPath(
            `/api/users/orchid_pro/profile_image/${orchidPro.cognito_sub}`,
          )}
          className={getClasses(
            styles["orchid-pro-avatar__main"],
            mainClassName,
          )}
          width={120}
          height={120}
        />
      )}
      {!hideGroup &&
        (orchidPro.heard_about_orchid_via === "AMHC" ||
        orchidPro.practice_group_name === "AMHC" ? null : (
          <OrchidProPracticeGroupIcon
            onClick={redirectToGroupPage}
            practice_group_name={
              orchidPro.practice_group_name
                ? orchidPro.practice_group_name
                : null
            }
            className={getClasses(
              styles["orchid-pro-avatar__sub"],
              groupClassName,
            )}
            width={isPhonePortrait ? 18 : 45}
            height={isPhonePortrait ? 18 : 45}
          />
        ))}
    </div>
  );
};

export default OrchidProAvatar;
