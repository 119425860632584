import { getErrorDetail } from "components/utility/utils";
import { fetchOrchidAPI } from "utils/api";

export default async function getAppointmentTypes(
  orchidProUserId: string,
  fetchOptions?: RequestInit,
) {
  const response = await fetchOrchidAPI(
    `/api/scheduler/v1/appointment_types/${orchidProUserId}`,
    fetchOptions,
  );
  if (!response.ok) {
    throw new Error(
      `failed to fetch session types: ${getErrorDetail(await response.text())}`,
    );
  }
  return response.json();
}
