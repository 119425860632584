import { getErrorDetail } from "components/utility/utils";
import { fetchOrchidAPI } from "utils/api";

/**
 * Apply coupon discount and get end price
 */
export default async function applyCouponDiscount(
  appointmentTypeLabel: string,
  couponName: string | null,
  orchidProCognitoSub: string,
) {
  const response = await fetchOrchidAPI(
    "/api/scheduler/v1/payment/appointment_coupon_discount",
    {
      method: "POST",
      body: JSON.stringify({
        appointment_type_label: appointmentTypeLabel,
        coupon_name: couponName,
        orchid_pro_cognito_sub: orchidProCognitoSub,
      }),
    },
  );

  if (!response.ok) {
    throw new Error(getErrorDetail(await response.text()));
  }

  return response.json();
}
