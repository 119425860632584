import { useEffect, useState } from "react";

export default function useForegroundInterval(
  callback: Function,
  delay: number,
  dependencies: any[],
) {
  const [visibilityState, setVisibilityState] = useState(
    document.visibilityState,
  );

  useEffect(() => {
    const onVisibilityChange = () => {
      setVisibilityState(document.visibilityState);
    };

    document.addEventListener("visibilitychange", onVisibilityChange);
    return () =>
      document.removeEventListener("visibilitychange", onVisibilityChange);
  });

  useEffect(() => {
    if (!visibilityState) {
      return;
    }

    callback();

    const interval = setInterval(callback, delay);

    return () => clearInterval(interval);
    // Dependencies cannot be verified by eslint when using the spread operator - please update the dependency array carefully!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callback, delay, visibilityState, ...dependencies]);
}
