import { getErrorDetail } from "components/utility/utils";
import { fetchOrchidAPI } from "utils/api";

/**
 * Checkout one time
 */
export default async function checkoutOneTime(appointmentId: string) {
  const res = await fetchOrchidAPI(
    "/api/scheduler/v1/payment/checkout_one_time",
    {
      method: "POST",
      body: JSON.stringify({ timeslot_id: appointmentId }),
    },
  );
  if (!res.ok) {
    throw new Error(`checkout failed: ${getErrorDetail(await res.text())}`);
  }

  return res.json();
}
