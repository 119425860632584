/**
 * intercom app id
 */

import { IS_DEV_ENV } from "./isDevEnv";

// intercom app id by env
export const INTERCOM_APP_ID = IS_DEV_ENV ? "mfl56y1q" : "wekubn67";

/**
 * hide intercom for following routes
 */
export const HIDE_INTERCOM_ROUTES = [
  "/pro-dashboard/messages",
  "/pro-dashboard/notes-and-forms",
  "/pro-dashboard/orchid-storage",
  "/pro-dashboard/audio-recorder",
  "/member-dashboard/notes-and-forms",
  "/member-dashboard/messages",
  "/video-chat",
];

export const ROUTES_WITH_LARGER_INTERCOM_MARGIN = [
  "/pro-dashboard/settings/",
];
