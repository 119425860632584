import { getErrorDetail } from "components/utility/utils";
import { fetchOrchidAPI } from "utils/api";

/**
 * Books availability
 */
export default async function booksAvailability({
  appointmentLength,
  appointmentStart,
  orchidProUserId,
  appointmentTypeLabel,
  couponName,
  inviteCode,
  appointmentHeldPlace,
}: {
  appointmentLength: number;
  appointmentStart: string;
  orchidProUserId: string;
  appointmentTypeLabel: string;
  couponName: string | null;
  inviteCode: string | null;
  appointmentHeldPlace: string | null;
}) {
  const response = await fetchOrchidAPI(
    "/api/scheduler/v1/timeslot_patient/books_availability",
    {
      method: "POST",
      body: JSON.stringify({
        timeslot_length_minute: appointmentLength,
        timeslot_datetime_start: appointmentStart,
        orchid_pro_cognito_sub: orchidProUserId,
        appointment_type_label: appointmentTypeLabel,
        coupon_name: couponName,
        invite_code: inviteCode,
        place_session_held_outside_of_orchid: appointmentHeldPlace,
      }),
    },
  );
  if (!response.ok) {
    throw new Error(
      `failed to confirm your schedule: ${getErrorDetail(
        await response.text(),
      )}`,
    );
  }

  return response.json();
}
