export const COLOMBIA_DEPARTMENT_OPTIONS = [
  { value: "AMA", label: "Amazonas" },
  { value: "ANT", label: "Antioquia" },
  { value: "ARA", label: "Arauca" },
  { value: "ATL", label: "Atlántico" },
  { value: "BOL", label: "Bolívar" },
  { value: "BOY", label: "Boyacá" },
  { value: "CAL", label: "Caldas" },
  { value: "CAQ", label: "Caquetá" },
  { value: "CAS", label: "Casanare" },
  { value: "CAU", label: "Cauca" },
  { value: "CES", label: "Cesar" },
  { value: "CHO", label: "Chocó" },
  { value: "COR", label: "Córdoba" },
  { value: "CUN", label: "Cundinamarca" },
  { value: "DC", label: "Bogotá, D.C." },
  { value: "GUA", label: "Guainía" },
  { value: "GUV", label: "Guaviare" },
  { value: "HUI", label: "Huila" },
  { value: "LAG", label: "La Guajira" },
  { value: "MAG", label: "Magdalena" },
  { value: "MET", label: "Meta" },
  { value: "NAR", label: "Nariño" },
  { value: "NSA", label: "Norte de Santander" },
  { value: "PUT", label: "Putumayo" },
  { value: "QUI", label: "Quindío" },
  { value: "RIS", label: "Risaralda" },
  { value: "SAN", label: "Santander" },
  { value: "SAP", label: "San Andrés and Providencia" },
  { value: "SUC", label: "Sucre" },
  { value: "TOL", label: "Tolima" },
  { value: "VAC", label: "Valle del Cauca" },
  { value: "VAU", label: "Vaupés" },
  { value: "VIC", label: "Vichada" },
];
