import { loadStripe } from "@stripe/stripe-js";
import { getErrorDetail } from "components/utility/utils";
import { fetchOrchidAPI } from "utils/api";

/**
 * Initialize stripe
 */
export default async function queryStripePromise() {
  const res = await fetchOrchidAPI(`/api/scheduler/v1/payment/config`);
  if (!res.ok) {
    throw new Error(
      `failed to initialize stripe: ${getErrorDetail(await res.text())}`,
    );
  }
  const config = await res.json();
  return loadStripe(config.publishable_key);
}
