import { getErrorDetail } from "components/utility/utils";
import { fetchOrchidAPI } from "utils/api";

/**
 * Query payment methods
 */
export default async function queryPaymentMethods() {
  const res = await fetchOrchidAPI(
    "/api/scheduler/v1/payment/saved_payment_methods",
  );
  if (!res.ok) {
    throw new Error(
      `failed to get saved payment methods: ${getErrorDetail(
        await res.text(),
      )}`,
    );
  }

  const data = await res.json();
  return data.payment_methods;
}
