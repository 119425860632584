import { showLoginModal } from "store/authSlice";
import { store } from "store/store";
import { fetchOrchidAPI } from "./api";
import { ENABLE_INACTIVITY_LOGOUT } from "constants/featureFlags";

export const LOGOUT_AFTER_INACTIVE_MILLIS = 59 * 60 * 1000;
export const INACTIVITY_CHECK_INTERVAL_MILLIS = 1 * 60 * 1000;

// export const LOGOUT_AFTER_INACTIVE_MILLIS = 15 * 1000;
// export const INACTIVITY_CHECK_INTERVAL_MILLIS = 1 * 1000;

const COOKIE_NAME = "orchid_last_active";
const COOKIE_MAX_AGE = 30 * 24 * 60 * 60; // at least as long as the refresh token is valid; will be cleaned up manually on forced logout

export function hasLastActiveCookie(): boolean {
  return document.cookie.includes(COOKIE_NAME);
}

function getLastActiveCookie(): number {
  // The first version could have stored the cookie for different paths - in such a case, we need to ignore any older cookies
  const lastActiveCookieEntries = document.cookie
    .split(";")
    .filter((cookie) => cookie.trim().startsWith(`${COOKIE_NAME}=`));

  const lastActiveValues = lastActiveCookieEntries.map((el) =>
    parseInt(el.split("=")[1]),
  );

  return Math.max(...lastActiveValues);
}

function setLastActiveCookie(value: number) {
  document.cookie = `${COOKIE_NAME}=;max-age=0`; // clean up any leftover cookie at the current path
  document.cookie = `${COOKIE_NAME}=${value};path=/;max-age=${COOKIE_MAX_AGE};samesite=strict;secure`;
}

export function resetInactivityTimeout() {
  if (!ENABLE_INACTIVITY_LOGOUT) {
    return;
  }

  setLastActiveCookie(Date.now());
}

function getTimeSinceLastActivityMillis(): number {
  const lastActiveDate = getLastActiveCookie();

  if (!isFinite(lastActiveDate)) {
    return Infinity;
  }

  const timeSinceLastActivityMillis = Date.now() - lastActiveDate;

  return timeSinceLastActivityMillis;
}

export function hasExceededInactivityLimit(): boolean {
  const timeSinceLastActivity = getTimeSinceLastActivityMillis();
  return timeSinceLastActivity > LOGOUT_AFTER_INACTIVE_MILLIS;
}

export function clearInactivityCookie() {
  document.cookie = `${COOKIE_NAME}=;max-age=0;path=/`;
}

export function setLastActiveIfNewer(value: number) {
  const lastActive = getLastActiveCookie();

  if (value <= lastActive) {
    return;
  }

  setLastActiveCookie(value);
}

export async function logoutIfInactive() {
  if (!ENABLE_INACTIVITY_LOGOUT) {
    return;
  }

  if (hasExceededInactivityLimit()) {
    clearInactivityCookie();
    await fetchOrchidAPI("/api/login/v1/logout", {
      method: "POST",
    });
    store.dispatch(showLoginModal());
  }
}
