import "sentry/sentry";

import { createRoot } from "react-dom/client";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import AppContextProvider from "./contexts";
import * as serviceWorker from "./serviceWorker";
import { store } from "./store/store";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <BrowserRouter>
    <ReduxProvider store={store}>
      <AppContextProvider>
        <App />
      </AppContextProvider>
    </ReduxProvider>
  </BrowserRouter>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

navigator.serviceWorker?.register(
  `${process.env.PUBLIC_URL}/drive/orchid-drive-download-service-worker.js`,
);
