import * as Sentry from "@sentry/browser";
import { DEFAULT_TIMEZONE } from "constants/defaultTimezone";
import { ENABLE_INACTIVITY_LOGOUT } from "constants/featureFlags";
import useForegroundInterval from "hooks/useForegroundInterval";
import moment from "moment-timezone";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import queryUserInfo from "services/queryUserInfo";
import {
  loginModalShown,
  selectPendingLoginModal,
  setHasGuardianToken,
} from "store/authSlice";
import { setAmplitudeUser } from "utils/amplitude";
import { fetchOrchidAPI } from "utils/api";
import { getUserLastActiveInExtension } from "utils/extensionUtils";
import getLocalTimeZone from "utils/getLocalTimeZone";
import {
  hasExceededInactivityLimit,
  hasLastActiveCookie,
  INACTIVITY_CHECK_INTERVAL_MILLIS,
  logoutIfInactive,
  resetInactivityTimeout,
  setLastActiveIfNewer,
} from "utils/inactivityLogoutUtils";
import isTestUser from "utils/isTestUser";

export const UserContext = createContext<{
  user: User | null | undefined;
  defaultUserTimezone: TimeZone;
  updateUserInfo: () => Promise<User | null>;
}>({
  user: undefined,
  defaultUserTimezone: DEFAULT_TIMEZONE,
  updateUserInfo: () => Promise.resolve(null),
});

type Props = { children: ReactNode };

export const UserContextProvider = ({ children }: Props) => {
  // when user is null meaning that the user has successfully logged in once, but failed to renew token afterwards
  const [user, setUser] = useState<User | null | undefined>(undefined);

  const dispatch = useDispatch();
  const pendingLoginModal = useSelector(selectPendingLoginModal);

  useEffect(() => {
    if (!pendingLoginModal) {
      return;
    }

    setUser(null);
    dispatch(loginModalShown());
  }, [pendingLoginModal, dispatch]);

  // fallback to browser timezone
  const defaultUserTimezone = useMemo(() => {
    return user?.timezone && moment.tz.names().includes(user.timezone)
      ? user.timezone
      : getLocalTimeZone();
  }, [user]);

  const updateUserInfo = useCallback(async () => {
    try {
      const user = await queryUserInfo();

      if (user) {
        Sentry.setUser({ id: user?.sub });
      }

      dispatch(setHasGuardianToken(!!user?.guardian_token_exp));

      const replay = Sentry.getReplay();
      if (!user || (isTestUser(user) && Math.random() < 0.1)) {
        // Sample 10% of the sessions for non-test users
        replay?.start();
      } else {
        replay?.stop();
      }

      // Until we sign a BAA with Amplitude, we will only identify events coming from pros.
      if (user && user.user_type === "orchid_pro") {
        setAmplitudeUser(user.sub, {
          firstName: user.first_name,
          lastName: user.last_name,
          fullName:
            user.first_name && user.last_name
              ? `${user.first_name} ${user.last_name}`
              : undefined,
          email: user.email,
          businessName: user.business_entity_name,
          userType: user.user_type,
          isTestAccount: user.is_test_user,
        });
      }

      setUser((prevUser) => {
        const currentUser =
          new Date(user?.modify_datetime || 0) >
          new Date(prevUser?.modify_datetime || 0)
            ? user
            : prevUser;
        return currentUser || null;
      });
      return user;
    } catch (error) {
      console.error(error);
    }

    return null;
  }, [dispatch]);

  useEffect(() => {
    if (
      ENABLE_INACTIVITY_LOGOUT &&
      hasExceededInactivityLimit() &&
      hasLastActiveCookie()
    ) {
      fetchOrchidAPI("/api/login/v1/logout", {
        method: "POST",
      });

      setUser(null);
      return;
    }

    updateUserInfo();
  }, [updateUserInfo]);

  useEffect(() => {
    if (!user) {
      return;
    }

    resetInactivityTimeout();

    window.addEventListener("click", resetInactivityTimeout);
    window.addEventListener("keydown", resetInactivityTimeout);
    return () => {
      window.removeEventListener("click", resetInactivityTimeout);
      window.removeEventListener("keydown", resetInactivityTimeout);
    };
  }, [user]);

  useForegroundInterval(
    async () => {
      if (!user) {
        return;
      }

      const lastActiveInExtension = await getUserLastActiveInExtension();
      setLastActiveIfNewer(lastActiveInExtension);

      logoutIfInactive();
    },
    INACTIVITY_CHECK_INTERVAL_MILLIS,
    [user],
  );

  return (
    <UserContext.Provider
      value={{
        user,
        defaultUserTimezone,
        updateUserInfo,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
