import React, { ComponentPropsWithoutRef } from "react";
import { apiHostAndPath } from "utils/api";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import styles from "./styles.module.scss";
import getClasses from "utils/getClasses";
import useId from "hooks/useId";
import RoundedImageWithFallback from "components/rounded-image-with-fallback";

type Props = {
  practice_group_name: string | null;
} & ComponentPropsWithoutRef<typeof RoundedImageWithFallback>;

const OrchidProPracticeGroupIcon = ({
  practice_group_name,
  className,
  ...props
}: Props) => {
  const id = useId();

  return !practice_group_name ? null : (
    <OverlayTrigger
      placement="right"
      overlay={<Tooltip id={id}>{practice_group_name}</Tooltip>}
    >
      <RoundedImageWithFallback
        src={apiHostAndPath(
          `/users/practice_group/logo_image/${encodeURIComponent(
            practice_group_name,
          )}`,
        )}
        className={getClasses(
          className,
          styles["orchid-pro-practice-group-icon"],
        )}
        {...props}
      />
    </OverlayTrigger>
  );
};

export default OrchidProPracticeGroupIcon;
